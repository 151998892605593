<template>
  <OneSignalEditView disabled />
</template>

<script>
import OneSignalEditView from '@/views/oneSignal/OneSignalEditView'

export default {
  name: 'OneSignalView',
  components: {
    OneSignalEditView
  }
}
</script>
